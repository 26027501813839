.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.text-center {
  margin: 0 auto !important;
  display: flex;
  justify-content: center;
  align-items: center;

}


.text-center button {

  margin-left: auto;
  z-index: 2;
  height: 85px;
  width: 85px;
  margin-right: 180px;
  border-radius: 50px;
  background: #757272;
  color: #fff;
  font-weight: 700;
  border: navajowhite;
  margin: auto;
  z-index: 11;
  position: absolute;


}



.text-center img {


  position: absolute;

  width: 26%;
  right: 161px;

  top: 157px;

  filter: contrast(0) !important;
  transform: rotate(180deg);
  filter: brightness(0) !important;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 99999;
  /* margin-left: -261px;
  z-index: 99999;
  height: 85px;
  margin-top: 176px;
  width: 85px;
  border-radius: 50px;
  background: #252323;
  color: #fff;
  font-weight: 700;
  border: navajowhite; */
}



.text-center1 {
  margin: 0 auto !important;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* height: 756px; */
}

.headers {
  width: 100%;
  background-color: black;
  padding: 40px 0px;
}


.butn>div {
  position: relative;
  width: 80vw;
  max-width: 445px;
  height: 80vw;
  max-height: 445px;
  object-fit: contain;
  flex-shrink: 0;
  z-index: 5;
  pointer-events: none;
  position: relative;
  z-index: 205;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-width: 500px; */
  width: 100%;
  /* min-width: 100px; */
  min-height: 700px;
  background-color: #d1cdcd61;
  z-index: 999;
  position: absolute;
}